import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.string.iterator";
import "core-js/modules/es6.set";
import _toConsumableArray from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/toConsumableArray.js";
import "core-js/modules/es6.function.name";
import "core-js/modules/es6.regexp.to-string";
import _defineProperty from "/root/workspace/crm_web_5uHk/node_modules/_@babel_runtime-corejs2@7.25.4@@babel/runtime-corejs2/helpers/esm/defineProperty.js";
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MyCustomerIndex, MyCustomerTop, FollwupIndex, FollwupAdd, getLabelpeer as _getLabelpeer, getCustomerLevel as _getCustomerLevel, MyCustomerSetlevel, CustomerDeliverIndex, Majorlist, agentFlowerNameList } from '@/api/ceshi_xiugai/whole';
import DisplaySettingser from '@/components/DisplaySettings/Settings';
import Head from '@/components/head/index';
import studentLevel from '@/components/studentLevel/index';
import { getStructureListArr } from '@/api/PublicAPI';
export default {
  name: 'CollegeManagement',
  data: function data() {
    var _ref;
    return _ref = {
      //系统教程
      tab: 0,
      course: this.$route.query.course,
      prohibit: false,
      //系统教程
      flower_Array: [],
      //花名
      sea_Array: [{
        id: -1,
        name: '全部'
      }, {
        id: 0,
        name: '私海'
      }, {
        id: 1,
        name: '部门公海'
      }, {
        id: 2,
        name: '部门二级公海'
      }],
      flowerNameId: '',
      Intendeds: [],
      Intended: '',
      //意向专业
      nexttime: '',
      //下次回访时间
      next_follow_stime: '',
      next_follow_etime: '',
      start_follow_time: '',
      end_follow_time: '',
      title: '已共享客户',
      reverse: true,
      val: '',
      comSharershow: false,
      comPlatshow: false,
      comNameshow: false,
      comWebsiteshow: false,
      isShow: false,
      nexttimeshow: false,
      createtimeshow: false,
      noteAppendedshow: false,
      shareShow: false,
      finalFollowshow: false,
      ittnzyshow: false,
      followUpPerson: false,
      createnameshow: false,
      lasttimeshow: false,
      remarksshow: false,
      labelpeersshow: false,
      showittnzy: '',
      //意向专业
      Batchstudentlevel: '',
      finalFollow: '',
      shareFollow: '',
      comSharer: '',
      comPlat: '',
      comName: '',
      comWebsite: '',
      remarks: '',
      noteAppended: '',
      labelpeers: '',
      //客户标签绑定值
      labelpeersEs: [],
      //客户标签
      source_client_id: '',
      //花名id
      seaType: '',
      //客户类型
      createtime: [],
      //创建时间
      create_stime: '',
      //	创建开始时间
      create_etime: '',
      //	创建结束时间
      start_follow_s: '',
      end_follow_e: '',
      create_realname: '',
      structure_idEs: '',
      //组织部门
      GetFieldInfodata2: [],
      resultList: '',
      activities: [],
      //跟进时间线
      // 列表请求参数
      query: {
        page: 1,
        pagesize: 10,
        total: 0 //总条数，不是总页数
      },
      followUp: '',
      start_sea_time: '',
      //进入私海时间（开始）
      end_sea_time: ''
    }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "start_follow_time", ''), "end_follow_time", ''), "Student", ''), "ClassSearch", ''), "list_type", 4), "customer_level", ''), "followUpId", ''), "followUpIdES", ''), "followUpEs", {
      //跟进
      name: '',
      theme: '',
      type: 1,
      next_time: ''
    }), "calendarTypeOptions", [
    //跟进类型
    {
      id: 1,
      label: '电话'
    }, {
      id: 2,
      label: 'QQ'
    }, {
      id: 3,
      label: '微信'
    }, {
      id: 4,
      label: '面聊'
    }]), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "currentPage4", 1), "ptionse", 1), "listLoading", true), "tableData", []), "rules", {
      title: [{
        required: true,
        message: '请输入院校名称',
        trigger: 'blur'
      }]
    }), "textMap", {
      update: '编辑院校',
      create: '新建院校'
    }), "dialogStatus", ''), "storageTime", []), "customer_id", []), "customer_data_id", []), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "is_top", ''), "establish", '0'), "finalFollowUp", '0'), "nextVisit", '0'), "shareVisit", ''), "userName", ''), "value1", ''), "customer", []), "displaySettings", []), "displaySettingsWhole", []), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_ref, "dataTiem", ''), "dataEnd", ''), "NumberEs", 2), "bumenoptionsName", ''), "bumenoptionsId", ''), "bumenoptions", []), "ReceiverMentShow", false);
  },
  created: function created() {
    //当前设定的日期时间
    var d = new Date();
    var year1, month1, day1;
    var _ref2 = [d.getFullYear(), d.getMonth(), d.getDate()];
    year1 = _ref2[0];
    month1 = _ref2[1];
    day1 = _ref2[2];
    var date1 = new Date(year1, month1, day1, 23, 59, 59);
    this.createtime.push(date1);
    //前一天设定的日期时间
    var year2, month2, day2;
    d.setTime(d.getTime() - 24 * 60 * 60 * 1000);
    var _ref3 = [d.getFullYear(), d.getMonth(), d.getDate()];
    year2 = _ref3[0];
    month2 = _ref3[1];
    day2 = _ref3[2];
    var date2 = new Date(year2, month2, 0, 24);
    this.createtime.unshift(date2);
    this.create_stime = Date.parse(this.createtime[0]).toString().substring(0, 10);
    this.create_etime = Date.parse(this.createtime[1]).toString().substring(0, 10);
  },
  mounted: function mounted() {
    var _this = this;
    if (this.course != 1) {
      this.seaType = -1;
      this.getLabelpeer();
      this.getList(this.query.page, this.query.pagesize);
      this.getCustomerLevel();
      // 11111111
      this.callname = this.$store.getters.callname;
      this.callpass = this.$store.getters.callpass;
      var that = this;
      // 控制表格滚动条
      this.$nextTick(function () {
        var mutationObserver = new MutationObserver(function (mutations) {
          that.setHeight();
        });
        mutationObserver.observe(_this.$refs.multipleTable.bodyWrapper, {
          attributes: true,
          subtree: true
        });
      });
      this.majorDedMaj(); //意向专业
      this.flowerArray(); //花名
    } else {}
  },
  components: {
    Head: Head,
    studentLevel: studentLevel,
    DisplaySettingser: DisplaySettingser
  },
  methods: {
    //表头显示搜索框
    Activeshow: function Activeshow(item) {
      var _this2 = this;
      if (item === '下次回访日期') {
        this.nexttimeshow = true;
      } else if (item === '创建日期') {
        this.createtimeshow = true;
      } else if (item === '意向专业') {
        this.ittnzyshow = true;
      } else if (item === '创建人') {
        this.createnameshow = true;
      } else if (item === '备注') {
        this.remarksshow = true;
      } else if (item === '创建附注') {
        this.noteAppendedshow = true;
      } else if (item == '客户标签') {
        this.labelpeersshow = true;
      } else if (item == '最后跟进日期') {
        this.finalFollowshow = true;
      } else if (item == '共享日期') {
        this.shareShow = true;
      } else if (item == '跟进人') {
        this.followUpPerson = true;
      } else if (item === '共享人') {
        this.comSharershow = true;
      } else if (item === '推广平台') {
        this.comPlatshow = true;
      } else if (item === '推广项目') {
        this.comNameshow = true;
      } else if (item === '推广地址') {
        this.comWebsiteshow = true;
      } else if (item === '数据所属部门') {
        this.ReceiverMentShow = true;
        getStructureListArr({
          type: '1',
          get_type: '0'
        }).then(function (res) {
          //所属部门
          _this2.bumenoptions = res.data;
        });
      }
    },
    //点×
    Deletesshow: function Deletesshow(item) {
      this.query.page = 1;
      if (item === '下次回访日期') {
        this.nexttimeshow = false;
        this.next_follow_stime = '';
        this.next_follow_etime = '';
        this.nexttime = '';
      } else if (item === '最后跟进日期') {
        this.finalFollowshow = false;
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.finalFollow = '';
      } else if (item === '创建日期') {
        this.createtimeshow = false;
        this.create_stime = '';
        this.create_etime = '';
        this.createtime = '';
      } else if (item === '意向专业') {
        this.ittnzyshow = false;
        this.showittnzy = '';
      } else if (item === '创建人') {
        this.createnameshow = false;
        this.create_realname = '';
      } else if (item === '备注') {
        this.remarksshow = false;
        this.remarks = '';
      } else if (item === '创建附注') {
        this.noteAppendedshow = false;
        this.noteAppended = '';
      } else if (item === '客户标签') {
        this.labelpeersshow = false;
        this.labelpeers = '';
      } else if (item === '共享日期') {
        this.shareShow = false;
        this.shareVisit = '';
      } else if (item === '跟进人') {
        this.followUpPerson = false;
        this.followUp = '';
      } else if (item === '共享人') {
        this.comSharershow = false;
        this.comSharer = '';
      } else if (item === '推广平台') {
        this.comPlatshow = false;
        this.comPlat = '';
      } else if (item === '推广项目') {
        this.comNameshow = false;
        this.comName = '';
      } else if (item === '推广地址') {
        this.comWebsiteshow = false;
        this.comWebsite = '';
      } else if (item === '数据所属部门') {
        this.ReceiverMentShow = false;
        this.bumenoptionsName = '';
        this.bumenoptionsId = '';
      }
      this.getList(this.query.page, this.query.pagesize);
    },
    handleChange: function handleChange(val) {
      //所属部门
      var share = val.map(function (e) {
        return e[e.length - 1];
      }).toString(); // 部门的最后一位ID
      this.bumenoptionsId = share == ',' ? '' : share;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    customerLabel: function customerLabel(item) {
      //客户标签筛选
      this.labelpeers = item;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    majorDedMaj: function majorDedMaj() {
      var _this3 = this;
      //意向专业默认
      Majorlist().then(function (res) {
        _this3.Intendeds = res.data.major_list;
      });
    },
    flowerArray: function flowerArray() {
      var _this4 = this;
      //花名
      agentFlowerNameList().then(function (res) {
        _this4.flower_Array = res.data;
      });
    },
    getList: function getList(page, pagesize) {
      var _this$resultList,
        _this5 = this;
      //账号客户列表
      this.listLoading = true;
      this.resultList = (_this$resultList = {
        page: String(page),
        pagesize: String(pagesize),
        follow_name: this.followUp,
        start_sea_time: this.start_sea_time,
        end_sea_time: this.end_sea_time,
        start_follow_time: this.start_follow_time,
        end_follow_time: this.end_follow_time,
        customer_levels: this.Student,
        wxnum_mobile_cname: this.ClassSearch,
        list_type: this.list_type,
        ittnzy: this.Intended,
        next_follow_stime: this.next_follow_stime,
        next_follow_etime: this.next_follow_etime
      }, _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_this$resultList, "start_follow_time", this.start_follow_time), "end_follow_time", this.end_follow_time), "share_time_s", this.start_follow_s), "share_time_e", this.end_follow_e), "ittnzy", this.showittnzy), "source_client_id", this.source_client_id), "sea_type", this.seaType), "create_stime", this.create_stime), "create_etime", this.create_etime), "create_realname", this.create_realname), _defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_defineProperty(_this$resultList, "structure_ids", this.structure_idEs), "create_time_order", this.establish), "follow_time_order", this.finalFollowUp), "next_time_order", this.nextVisit), "share_time_asc", this.shareVisit), "labelpeers", this.labelpeers), "notes", this.remarks), "create_notes", this.noteAppended), "sharer_name", this.comSharer), "platform_name", this.comPlat), _defineProperty(_defineProperty(_defineProperty(_this$resultList, "communication_name", this.comName), "communication_website", this.comWebsite), "now_structure_ids", this.bumenoptionsId));
      MyCustomerIndex(this.resultList).then(function (respomse) {
        var data = respomse.data;
        data.data.map(function (item) {
          item.create_notesE = item.create_notes;
          if (item.create_notes && item.create_notes.length > 20) {
            item.create_notes = item.create_notes.substring(0, 20) + '......';
          }
        });
        _this5.tableData = data.data;
        _this5.query.total = data.total;
        _this5.listLoading = false;
        _this5.displaySettingsWhole = [];
        if (respomse.data.fields) {
          var indexCreater = respomse.data.fields.indexOf('创建人');
          if (indexCreater !== -1) {
            respomse.data.fields.splice(indexCreater, 1);
          }
        }
        if (respomse.data.base_fields) {
          delete respomse.data.base_fields.create_realname;
        }
        _this5.displaySettings = respomse.data.fields;
        localStorage.setItem('names', JSON.stringify(respomse.data.fields));
        for (var item in respomse.data.base_fields) {
          _this5.displaySettingsWhole.push(respomse.data.base_fields[item]);
        }
        if (_this5.callname != undefined) {
          _this5.displaySettingsWhole.push('操作');
          _this5.operationEs = true;
        } else {
          _this5.operationEs = false;
        }
      });
    },
    getCustomerLevel: function getCustomerLevel() {
      var _this6 = this;
      _getCustomerLevel().then(function (res) {
        res.data.map(function (e) {
          e.label = e.name;
          e.value = e.id;
        });
        for (var item in res.data) {
          if (res.data[item].children != undefined) {
            res.data[item].children.map(function (dats) {
              dats.label = dats.name;
              dats.value = dats.id;
            });
          }
        }
        _this6.GetFieldInfodata2 = res.data;
      });
    },
    // 设置滚动条相关信息1111111111
    setHeight: function setHeight() {
      var widthValue = document.getElementById('customerTable').clientWidth + 'px';
      this.tableBodyWidth = document.getElementById('customerTable').childNodes[1].childNodes[0].clientWidth + 'px';
      var div = document.getElementById('tableLeft');
      div.style.width = widthValue;
      var div2 = document.getElementById('bodyLeft');
      div2.style.width = this.tableBodyWidth;
    },
    // 控制表格滚动条1111111
    exterHandleScroll: function exterHandleScroll() {
      var table = this.$refs.multipleTable.bodyWrapper;
      table.scrollLeft = this.$refs.tableLeft.scrollLeft;
    },
    // 客户标签
    getLabelpeer: function getLabelpeer() {
      var _this7 = this;
      _getLabelpeer().then(function (res) {
        //客户标签
        res.data.map(function (itemEs) {
          itemEs.id = String(itemEs.id);
        });
        _this7.labelpeersEs = res.data;
        _this7.customer = res.data;
      });
    },
    sortEs: function sortEs(column, prop, order) {
      // ascending 升序 、 descending降序 、 null 不排序
      if (column.prop == 'create_time') {
        //创建日期
        if (column.order == 'ascending') {
          this.establish = '1';
        } else if (column.order == 'descending') {
          this.establish = '2';
        } else {
          this.establish = '0';
        }
        this.finalFollowUp = '0';
        this.nextVisit = '0';
        this.shareVisit = '';
      } else if (column.prop == 'follw_time') {
        //最后跟进日期
        if (column.order == 'ascending') {
          this.finalFollowUp = '1';
        } else if (column.order == 'descending') {
          this.finalFollowUp = '2';
        } else {
          this.finalFollowUp = '0';
        }
        this.establish = '0';
        this.nextVisit = '0';
        this.shareVisit = '';
      } else if (column.prop == 'next_time') {
        //下次回访日期
        if (column.order == 'ascending') {
          this.nextVisit = '1';
        } else if (column.order == 'descending') {
          this.nextVisit = '2';
        } else {
          this.nextVisit = '0';
        }
        this.establish = '0';
        this.finalFollowUp = '0';
        this.shareVisit = '';
      } else if (column.prop == 'share_create_time') {
        //共享日期
        if (column.order == 'ascending') {
          this.shareVisit = '1';
        } else if (column.order == 'descending') {
          this.shareVisit = '2';
        } else {
          this.shareVisit = '';
        }
        this.establish = '0';
        this.nextVisit = '0';
        this.finalFollowUp = '0';
      }
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    goTodetails: function goTodetails(row, val) {
      //客户详情
      var routeUrl = this.$router.resolve({
        path: '/sharedList?Cid=' + row.customer_id + '&CData_id=' + row.customer_data_id + '&editRole=1&key=' + val.$index + '&page=' + this.query.page + '&pagesize=' + this.query.pagesize + '&list_type=' + this.list_type
      });
      var asas = JSON.stringify(this.resultList);
      localStorage.setItem('resultList', asas);
      window.open(routeUrl.href, '_blank');
    },
    IntendedMajor: function IntendedMajor() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    //花名
    flowerArrayEs: function flowerArrayEs() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    seaArrayEs: function seaArrayEs(val) {
      this.seaType = val;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    //意向专业
    shouwittnzyclick: function shouwittnzyclick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    comNameClick: function comNameClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    comSharerClick: function comSharerClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    comPlatClick: function comPlatClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    comWebsiteClick: function comWebsiteClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    nextDateTime: function nextDateTime(item) {
      //回访时间选择
      this.query.page = 1;
      if (this.nexttime == null) {
        this.next_follow_stime = '';
        this.next_follow_etime = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.next_follow_stime = this.nexttime[0].toString().substring(0, 10);
        this.next_follow_etime = this.nexttime[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    FinalFollowUpEs: function FinalFollowUpEs() {
      //最后跟进日期筛选
      this.query.page = 1;
      if (this.finalFollow == null) {
        this.start_follow_time = '';
        this.end_follow_time = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.start_follow_time = this.finalFollow[0].toString().substring(0, 10);
        this.end_follow_time = this.finalFollow[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    shareFollowUp: function shareFollowUp() {
      //共享日期筛选
      this.query.page = 1;
      if (this.shareFollow == null) {
        this.start_follow_s = '';
        this.end_follow_e = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.start_follow_s = this.shareFollow[0].toString().substring(0, 10);
        this.end_follow_e = this.shareFollow[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    //创建时间
    creatDateTime: function creatDateTime() {
      this.query.page = 1;
      if (this.createtime == null) {
        this.create_stime = '';
        this.create_etime = '';
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.create_stime = this.createtime[0].toString().substring(0, 10);
        this.create_etime = this.createtime[1].toString().substring(0, 10);
        this.getList(this.query.page, this.query.pagesize);
      }
    },
    //创建人
    createname: function createname() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    remarksClick: function remarksClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    noteAppendedClick: function noteAppendedClick() {
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    followUpClick: function followUpClick() {
      //跟进人筛选
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    // 学员级别
    studentLevel: function studentLevel(val) {
      this.Student = val;
      this.query.page = 1;
      this.getList(this.query.page, this.query.pagesize);
    },
    topOfList: function topOfList(item, id) {
      var _this8 = this;
      //置顶
      MyCustomerTop({
        is_top: item,
        customer_data_id: String(id)
      }).then(function (res) {
        if (item == 1) {
          _this8.$notify({
            title: '成功',
            message: '成功置顶',
            type: 'success',
            duration: 2000
          });
        } else {
          _this8.$notify({
            title: '成功',
            message: '成功取消置顶',
            type: 'success',
            duration: 2000
          });
        }
        _this8.getList(_this8.query.page, _this8.query.pagesize);
      });
    },
    ClassSearchInput: function ClassSearchInput() {
      this.query.page = 1;
      if (this.ClassSearch == '') {
        this.getList(this.query.page, this.query.pagesize);
      } else {
        this.getList(this.query.page, this.query.pagesize, this.ClassSearch);
      }
    },
    //每页最多显示条数10   20   30   50
    handleSizeChange: function handleSizeChange(val) {
      //每页 ${val} 条
      this.query.pagesize = val;
      this.getList(this.query.page, val);
    },
    //翻页
    handleCurrentChange: function handleCurrentChange(val) {
      //当前页
      this.query.page = val;
      this.getList(val, this.query.pagesize);
    },
    BatchModify: function BatchModify() {
      if (this.customer_data_id != '') {
        this.isShow = true;
      } else {
        this.$message({
          type: 'warning',
          message: '请选择客户'
        });
      }
    },
    PostMultipleUpdates: function PostMultipleUpdates(val) {
      var _this9 = this;
      //批量修改学员级别
      if (val == '') {
        this.Batchstudentlevel = '';
      } else {
        this.Batchstudentlevel = val[1] == undefined ? val[0] : val[1];
        if (this.customer_data_id != '') {
          MyCustomerSetlevel({
            customer_data_ids: String(this.customer_data_id),
            customer_level: String(this.Batchstudentlevel)
          }).then(function (res) {
            _this9.$notify({
              title: '成功',
              message: '修改成功',
              type: 'success'
            });
            _this9.customer_data_id = '';
            _this9.Batchstudentlevel = '';
            _this9.getList(_this9.query.page, _this9.query.pagesize);
          });
        } else {
          this.$message({
            type: 'warning',
            message: '请选择客户'
          });
        }
      }
    },
    handleSelectionChange: function handleSelectionChange(val) {
      //获取选中id
      var idEs = [];
      var dataId = [];
      for (var i = 0; i < val.length; i++) {
        dataId.push(val[i].customer_data_id);
        idEs.push(val[i].customer_id);
        this.is_top = val[i].is_top;
        this.userName = val[i].cname;
      }
      this.customer_data_id = _toConsumableArray(new Set(dataId));
      this.customer_id = _toConsumableArray(new Set(idEs));
    }
  }
};