var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "app-container CollegeManagement" }, [
    _vm.course != 1
      ? _c(
          "div",
          { staticStyle: { width: "100%" } },
          [
            _c(
              "el-header",
              { staticStyle: { height: "auto" } },
              [
                _c(
                  "el-row",
                  { staticStyle: { height: "32px" }, attrs: { gutter: 24 } },
                  [_c("Head", { attrs: { name: _vm.title } })],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  {
                    staticClass: "btn",
                    staticStyle: { overflow: "hidden", "margin-right": "0" },
                    attrs: { gutter: 24 },
                  },
                  [
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 8, lg: 8, xl: 5 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("student-level", {
                              on: { studentLevel: _vm.studentLevel },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 8, lg: 7, xl: 5 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("学员来源："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  size: "small",
                                  filterable: "",
                                  placeholder: "请搜索花名",
                                  clearable: "",
                                },
                                on: { change: _vm.flowerArrayEs },
                                model: {
                                  value: _vm.source_client_id,
                                  callback: function ($$v) {
                                    _vm.source_client_id = $$v
                                  },
                                  expression: "source_client_id",
                                },
                              },
                              _vm._l(_vm.flower_Array, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: {
                                    label: item.flower_name,
                                    value: item.id,
                                  },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 8, lg: 7, xl: 5 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("客户类型："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                staticStyle: { width: "180px" },
                                attrs: {
                                  size: "small",
                                  filterable: "",
                                  placeholder: "请选择客户类型",
                                  clearable: "",
                                },
                                on: { change: _vm.seaArrayEs },
                                model: {
                                  value: _vm.seaType,
                                  callback: function ($$v) {
                                    _vm.seaType = $$v
                                  },
                                  expression: "seaType",
                                },
                              },
                              _vm._l(_vm.sea_Array, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.name, value: item.id },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { xs: 24, sm: 8, md: 8, lg: 9, xl: 6 },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "source" },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("创建日期："),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "260px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.creatDateTime },
                              model: {
                                value: _vm.createtime,
                                callback: function ($$v) {
                                  _vm.createtime = $$v
                                },
                                expression: "createtime",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-left": "0",
                          "padding-right": "0",
                          "margin-top": "2px",
                          "margin-bottom": "20px",
                        },
                        attrs: { span: 2 },
                      },
                      [
                        _c("display-settingser", {
                          staticClass: "ml10 filter-item",
                          staticStyle: { float: "right" },
                          attrs: {
                            resultList: _vm.resultList,
                            list_type: _vm.list_type,
                            "display-arr": _vm.displaySettingsWhole,
                            "display-value": _vm.displaySettings,
                          },
                          on: {
                            "update:displayValue": function ($event) {
                              _vm.displaySettings = $event
                            },
                            "update:display-value": function ($event) {
                              _vm.displaySettings = $event
                            },
                          },
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      {
                        staticStyle: {
                          "padding-right": "0",
                          "margin-bottom": "20px",
                        },
                        attrs: { span: 4 },
                      },
                      [
                        _c(
                          "el-input",
                          {
                            staticClass: "input-with-select",
                            attrs: {
                              placeholder: "请搜索电话、名称",
                              size: "small",
                            },
                            on: { change: _vm.ClassSearchInput },
                            nativeOn: {
                              keyup: function ($event) {
                                if (
                                  !$event.type.indexOf("key") &&
                                  _vm._k(
                                    $event.keyCode,
                                    "enter",
                                    13,
                                    $event.key,
                                    "Enter"
                                  )
                                ) {
                                  return null
                                }
                                return _vm.ClassSearchInput($event)
                              },
                            },
                            model: {
                              value: _vm.ClassSearch,
                              callback: function ($$v) {
                                _vm.ClassSearch = $$v
                              },
                              expression: "ClassSearch",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                type: "primary",
                                size: "small",
                                icon: "el-icon-search",
                              },
                              on: { click: _vm.ClassSearchInput },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "el-row",
                  { attrs: { gutter: 24 } },
                  [
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.nexttimeshow,
                                expression: "nexttimeshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("下次回访时间："),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "234px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.nextDateTime },
                              model: {
                                value: _vm.nexttime,
                                callback: function ($$v) {
                                  _vm.nexttime = $$v
                                },
                                expression: "nexttime",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("下次回访日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ittnzyshow,
                                expression: "ittnzyshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("意向专业："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  filterable: "",
                                  placeholder: "请选择",
                                  size: "small",
                                  clearable: "",
                                },
                                on: { change: _vm.shouwittnzyclick },
                                model: {
                                  value: _vm.showittnzy,
                                  callback: function ($$v) {
                                    _vm.showittnzy = $$v
                                  },
                                  expression: "showittnzy",
                                },
                              },
                              _vm._l(_vm.Intendeds, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.title, value: item.id },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("意向专业")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.noteAppendedshow,
                                expression: "noteAppendedshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("创建附注："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索创建附注",
                                  size: "small",
                                },
                                on: { change: _vm.noteAppendedClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.noteAppendedClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.noteAppended,
                                  callback: function ($$v) {
                                    _vm.noteAppended = $$v
                                  },
                                  expression: "noteAppended",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.noteAppendedClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("创建附注")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.finalFollowshow,
                                expression: "finalFollowshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("最后跟进日期:"),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "234px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.FinalFollowUpEs },
                              model: {
                                value: _vm.finalFollow,
                                callback: function ($$v) {
                                  _vm.finalFollow = $$v
                                },
                                expression: "finalFollow",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("最后跟进日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.shareShow,
                                expression: "shareShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("共享日期:"),
                            ]),
                            _vm._v(" "),
                            _c("el-date-picker", {
                              staticStyle: { width: "234px !important" },
                              attrs: {
                                size: "small",
                                type: "datetimerange",
                                "start-placeholder": "开始日期",
                                "end-placeholder": "  结束日期",
                                "default-time": ["00:00:00", "23:59:59"],
                                "value-format": "timestamp",
                              },
                              on: { change: _vm.shareFollowUp },
                              model: {
                                value: _vm.shareFollow,
                                callback: function ($$v) {
                                  _vm.shareFollow = $$v
                                },
                                expression: "shareFollow",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("共享日期")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.createnameshow,
                                expression: "createnameshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("创建人:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索电话、名称",
                                  size: "small",
                                },
                                on: { change: _vm.createname },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.createname($event)
                                  },
                                },
                                model: {
                                  value: _vm.create_realname,
                                  callback: function ($$v) {
                                    _vm.create_realname = $$v
                                  },
                                  expression: "create_realname",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.createname },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("创建人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.followUpPerson,
                                expression: "followUpPerson",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("跟进人:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索跟进人",
                                  size: "small",
                                },
                                on: { change: _vm.followUpClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.followUpClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.followUp,
                                  callback: function ($$v) {
                                    _vm.followUp = $$v
                                  },
                                  expression: "followUp",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.followUpClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("跟进人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.remarksshow,
                                expression: "remarksshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("备注:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索备注",
                                  size: "small",
                                },
                                on: { change: _vm.remarksClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.remarksClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.remarks,
                                  callback: function ($$v) {
                                    _vm.remarks = $$v
                                  },
                                  expression: "remarks",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.remarksClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("备注")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.labelpeersshow,
                                expression: "labelpeersshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("客户标签:"),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-select",
                              {
                                attrs: { size: "small", placeholder: "请选择" },
                                on: { change: _vm.customerLabel },
                                model: {
                                  value: _vm.labelpeers,
                                  callback: function ($$v) {
                                    _vm.labelpeers = $$v
                                  },
                                  expression: "labelpeers",
                                },
                              },
                              _vm._l(_vm.labelpeersEs, function (item) {
                                return _c("el-option", {
                                  key: item.id,
                                  attrs: { label: item.value, value: item.id },
                                })
                              }),
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("客户标签")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.comSharershow,
                                expression: "comSharershow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("共享人："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索共享人",
                                  size: "small",
                                },
                                on: { change: _vm.comSharerClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.comSharerClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.comSharer,
                                  callback: function ($$v) {
                                    _vm.comSharer = $$v
                                  },
                                  expression: "comSharer",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.comSharerClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("共享人")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.comPlatshow,
                                expression: "comPlatshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("推广平台："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索推广平台",
                                  size: "small",
                                },
                                on: { change: _vm.comPlatClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.comPlatClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.comPlat,
                                  callback: function ($$v) {
                                    _vm.comPlat = $$v
                                  },
                                  expression: "comPlat",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.comPlatClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("推广平台")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.comNameshow,
                                expression: "comNameshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("推广项目："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索推广项目",
                                  size: "small",
                                },
                                on: { change: _vm.comNameClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.comNameClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.comName,
                                  callback: function ($$v) {
                                    _vm.comName = $$v
                                  },
                                  expression: "comName",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.comNameClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("推广项目")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.comWebsiteshow,
                                expression: "comWebsiteshow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c("span", { staticClass: "nameTitle" }, [
                              _vm._v("推广地址："),
                            ]),
                            _vm._v(" "),
                            _c(
                              "el-input",
                              {
                                staticStyle: { width: "250px" },
                                attrs: {
                                  placeholder: "请搜索推广地址",
                                  size: "small",
                                },
                                on: { change: _vm.comWebsiteClick },
                                nativeOn: {
                                  keyup: function ($event) {
                                    if (
                                      !$event.type.indexOf("key") &&
                                      _vm._k(
                                        $event.keyCode,
                                        "enter",
                                        13,
                                        $event.key,
                                        "Enter"
                                      )
                                    ) {
                                      return null
                                    }
                                    return _vm.comWebsiteClick($event)
                                  },
                                },
                                model: {
                                  value: _vm.comWebsite,
                                  callback: function ($$v) {
                                    _vm.comWebsite = $$v
                                  },
                                  expression: "comWebsite",
                                },
                              },
                              [
                                _c("el-button", {
                                  attrs: {
                                    slot: "append",
                                    type: "primary",
                                    size: "small",
                                    icon: "el-icon-search",
                                  },
                                  on: { click: _vm.comWebsiteClick },
                                  slot: "append",
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("推广地址")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                    _vm._v(" "),
                    _c(
                      "el-col",
                      { attrs: { xs: 24, sm: 16, md: 13, lg: 11, xl: 11 } },
                      [
                        _c(
                          "div",
                          {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.ReceiverMentShow,
                                expression: "ReceiverMentShow",
                              },
                            ],
                            staticStyle: { "margin-top": "15px" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "nameTitle projectScreening" },
                              [_vm._v("数据所属部门：")]
                            ),
                            _vm._v(" "),
                            _c("el-cascader", {
                              attrs: {
                                options: _vm.bumenoptions,
                                clearable: "",
                                filterable: "",
                                size: "small",
                                "expand-trigger": "hover",
                                placeholder: "请选择部门",
                                "collapse-tags": "",
                                props: {
                                  value: "id",
                                  label: "name",
                                  multiple: true,
                                },
                              },
                              on: { change: _vm.handleChange },
                              model: {
                                value: _vm.bumenoptionsName,
                                callback: function ($$v) {
                                  _vm.bumenoptionsName = $$v
                                },
                                expression: "bumenoptionsName",
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.Deletesshow("数据所属部门")
                                  },
                                },
                              },
                              [_c("i", { staticClass: "el-icon-close" })]
                            ),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "el-main",
              [
                [
                  _c(
                    "el-table",
                    {
                      ref: "multipleTable",
                      staticStyle: { width: "100%" },
                      attrs: {
                        data: _vm.tableData,
                        fit: "",
                        border: "",
                        id: "customerTable",
                        "header-cell-style": {
                          background: "#F9F9F9",
                          color: "#222222",
                        },
                      },
                      on: {
                        "sort-change": _vm.sortEs,
                        "selection-change": _vm.handleSelectionChange,
                      },
                    },
                    [
                      _vm.displaySettings.includes("序号")
                        ? _c("el-table-column", {
                            key: 2,
                            attrs: {
                              label: "序号",
                              align: "center",
                              width: "50",
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.$index +
                                              (_vm.query.page - 1) *
                                                _vm.query.pagesize +
                                              1
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              3946342523
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("置顶")
                        ? _c("el-table-column", {
                            key: 3,
                            attrs: { label: "置顶", align: "center" },
                            on: {
                              click: function ($event) {
                                return _vm.Activeshow()
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      scope.row.is_top == 0
                                        ? _c(
                                            "el-button",
                                            {
                                              attrs: {
                                                type: "primary",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.topOfList(
                                                    "1",
                                                    scope.row.customer_data_id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("置顶")]
                                          )
                                        : _c(
                                            "el-button",
                                            {
                                              staticStyle: {
                                                background: "#ffb400",
                                                "border-color": "#ffb400",
                                              },
                                              attrs: {
                                                type: "primary",
                                                size: "small",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.topOfList(
                                                    "0",
                                                    scope.row.customer_data_id
                                                  )
                                                },
                                              },
                                            },
                                            [_vm._v("取消置顶")]
                                          ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1419194492
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("客户名称")
                        ? _c("el-table-column", {
                            key: 4,
                            attrs: {
                              label: "客户名称",
                              prop: "cname",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("创建日期")
                        ? _c("el-table-column", {
                            key: 5,
                            attrs: {
                              label: "创建日期",
                              sortable: "custom",
                              width: "120",
                              prop: "create_time",
                              align: "center",
                              filters: [],
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("电话号码")
                        ? _c("el-table-column", {
                            key: 6,
                            attrs: {
                              label: "电话号码",
                              prop: "mobile",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("归属地")
                        ? _c("el-table-column", {
                            key: 7,
                            attrs: {
                              label: "归属地",
                              prop: "mobile_address",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("跟进")
                        ? _c("el-table-column", {
                            key: 71,
                            attrs: { label: "跟进", align: "center" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("微信号码")
                        ? _c("el-table-column", {
                            key: 8,
                            attrs: {
                              label: "微信号码",
                              prop: "wxnum",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("意向专业")
                        ? _c(
                            "el-table-column",
                            {
                              key: 9,
                              attrs: {
                                label: "意向专业",
                                prop: "ittnzy",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("意向专业")
                                      },
                                    },
                                  },
                                  [_vm._v("意向专业")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("跟进人")
                        ? _c(
                            "el-table-column",
                            {
                              key: 10,
                              attrs: {
                                label: "跟进人",
                                prop: "follow_realname",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "a",
                                          {
                                            staticStyle: { color: "#1890ff" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.goTodetails(
                                                  scope.row,
                                                  scope
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(scope.row.follow_realname)
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                826188959
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("跟进人")
                                      },
                                    },
                                  },
                                  [_vm._v("跟进人")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("最后跟进日期")
                        ? _c(
                            "el-table-column",
                            {
                              key: 11,
                              attrs: {
                                label: "最后跟进日期",
                                sortable: "custom",
                                width: "160",
                                prop: "follw_time",
                                align: "center",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("最后跟进日期")
                                      },
                                    },
                                  },
                                  [_vm._v("最后跟进日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("学员级别")
                        ? _c("el-table-column", {
                            key: 12,
                            attrs: {
                              label: "学员级别",
                              prop: "customer_level",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("共享日期")
                        ? _c(
                            "el-table-column",
                            {
                              key: 13,
                              attrs: {
                                label: "共享日期",
                                width: "120",
                                sortable: "custom",
                                prop: "share_create_time",
                                align: "center",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("共享日期")
                                      },
                                    },
                                  },
                                  [_vm._v("共享日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("学术成绩")
                        ? _c("el-table-column", {
                            key: 14,
                            attrs: {
                              label: "学术成绩",
                              prop: "academic_achievements",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("下次回访日期")
                        ? _c(
                            "el-table-column",
                            {
                              key: 16,
                              attrs: {
                                label: "下次回访日期",
                                sortable: "custom",
                                width: "170",
                                prop: "next_time",
                                align: "center",
                                filters: [],
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("下次回访日期")
                                      },
                                    },
                                  },
                                  [_vm._v("下次回访日期")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("报考项目")
                        ? _c("el-table-column", {
                            key: 17,
                            attrs: {
                              label: "报考项目",
                              prop: "ittnxm",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("客户标签")
                        ? _c(
                            "el-table-column",
                            {
                              key: 18,
                              attrs: {
                                label: "客户标签",
                                prop: "labelpeer",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("客户标签")
                                      },
                                    },
                                  },
                                  [_vm._v("客户标签")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("撞单次数")
                        ? _c("el-table-column", {
                            key: 19,
                            attrs: {
                              label: "撞单次数",
                              prop: "zdnum",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("QQ号")
                        ? _c("el-table-column", {
                            key: 20,
                            attrs: {
                              label: "QQ号",
                              prop: "qq",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("年龄")
                        ? _c("el-table-column", {
                            key: 21,
                            attrs: {
                              label: "年龄",
                              prop: "age",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("备注")
                        ? _c(
                            "el-table-column",
                            {
                              key: 22,
                              attrs: {
                                width: "68",
                                label: "备注",
                                align: "center",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "itemDes",
                                            attrs: {
                                              content: scope.row.notes,
                                              "popper-class": "toolp",
                                              placement: "top-start",
                                              effect: "light",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "dib ell",
                                                staticStyle: { width: "48px" },
                                              },
                                              [_vm._v(_vm._s(scope.row.notes))]
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                3724436026
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("备注")
                                      },
                                    },
                                  },
                                  [_vm._v("备注")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("创建附注")
                        ? _c(
                            "el-table-column",
                            {
                              key: 23,
                              attrs: { label: "创建附注", align: "center" },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        _c(
                                          "el-tooltip",
                                          {
                                            staticClass: "itemDes",
                                            attrs: {
                                              content: scope.row.create_notesE,
                                              "popper-class": "toolp",
                                              placement: "top-start",
                                              effect: "light",
                                            },
                                          },
                                          [
                                            _c("span", { staticClass: "dib" }, [
                                              _vm._v(
                                                _vm._s(scope.row.create_notes)
                                              ),
                                            ]),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1083207677
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("创建附注")
                                      },
                                    },
                                  },
                                  [_vm._v("创建附注")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("岗位")
                        ? _c("el-table-column", {
                            key: 23,
                            attrs: {
                              label: "岗位",
                              prop: "post",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("联系电话2")
                        ? _c("el-table-column", {
                            key: 24,
                            attrs: {
                              label: "联系电话2",
                              prop: "mobile1",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("归属地2")
                        ? _c("el-table-column", {
                            key: 25,
                            attrs: {
                              label: "归属地2",
                              prop: "mobile1_address",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("联系电话3")
                        ? _c("el-table-column", {
                            key: 26,
                            attrs: {
                              label: "联系电话3",
                              prop: "mobile2",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("归属地3")
                        ? _c("el-table-column", {
                            key: 27,
                            attrs: {
                              label: "归属地3",
                              prop: "mobile2_address",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("意向院校")
                        ? _c("el-table-column", {
                            key: 28,
                            attrs: {
                              label: "意向院校",
                              prop: "ittnyx",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("职业")
                        ? _c("el-table-column", {
                            key: 29,
                            attrs: {
                              label: "职业",
                              prop: "occupation",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("学员来源")
                        ? _c("el-table-column", {
                            key: 30,
                            attrs: {
                              label: "学员来源",
                              prop: "client_name",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("行业")
                        ? _c("el-table-column", {
                            key: 31,
                            attrs: {
                              label: "行业",
                              prop: "industry",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("意向简章")
                        ? _c("el-table-column", {
                            key: 32,
                            attrs: {
                              label: "意向简章",
                              prop: "ittnjz",
                              align: "center",
                              "max-height": "100",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("联系电话2")
                        ? _c("el-table-column", {
                            key: 33,
                            attrs: { label: "联系电话2", prop: "mobile1" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("当前学历")
                        ? _c("el-table-column", {
                            key: 34,
                            attrs: {
                              label: "当前学历",
                              prop: "graduation_xueli",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("原始创建人")
                        ? _c("el-table-column", {
                            key: 35,
                            attrs: {
                              label: "原始创建人",
                              prop: "old_create_realname",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("意向地区")
                        ? _c("el-table-column", {
                            key: 36,
                            attrs: {
                              label: "意向地区",
                              prop: "ittngj",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("发起沟通网址")
                        ? _c("el-table-column", {
                            key: 37,
                            attrs: {
                              label: "发起沟通网址",
                              prop: "communication_website",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("语言成绩")
                        ? _c("el-table-column", {
                            key: 38,
                            attrs: {
                              label: "语言成绩",
                              prop: "language_achievement",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("预算")
                        ? _c("el-table-column", {
                            key: 39,
                            attrs: {
                              label: "预算",
                              prop: "budget",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("座机")
                        ? _c("el-table-column", {
                            key: 40,
                            attrs: {
                              label: "座机",
                              prop: "telephone",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("毕业院校")
                        ? _c("el-table-column", {
                            key: 42,
                            attrs: {
                              label: "毕业院校",
                              prop: "graduate_school",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("组织部门")
                        ? _c(
                            "el-table-column",
                            {
                              key: 43,
                              attrs: {
                                label: "组织部门",
                                prop: "structure",
                                align: "center",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("组织部门")
                                      },
                                    },
                                  },
                                  [_vm._v("组织部门")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("共享人")
                        ? _c(
                            "el-table-column",
                            {
                              key: 58,
                              attrs: {
                                label: "共享人",
                                prop: "sharer_name",
                                align: "center",
                                width: "120px",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("共享人")
                                      },
                                    },
                                  },
                                  [_vm._v("共享人")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("推广平台")
                        ? _c(
                            "el-table-column",
                            {
                              key: 57,
                              attrs: {
                                label: "推广平台",
                                prop: "platform_name",
                                align: "center",
                                width: "120px",
                              },
                            },
                            [
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("推广平台")
                                      },
                                    },
                                  },
                                  [_vm._v("推广平台")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("推广项目")
                        ? _c(
                            "el-table-column",
                            {
                              key: 55,
                              attrs: {
                                label: "推广项目",
                                prop: "communication_name",
                                align: "center",
                                width: "120px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.communication_name.length > 30
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "itemDes",
                                                attrs: {
                                                  content:
                                                    scope.row
                                                      .communication_name,
                                                  "popper-class": "toolp",
                                                  placement: "top-start",
                                                  effect: "light",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.communication_name.slice(
                                                        0,
                                                        30
                                                      ) + "..."
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row.communication_name
                                                )
                                              ),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1452567585
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("推广项目")
                                      },
                                    },
                                  },
                                  [_vm._v("推广项目")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("推广地址")
                        ? _c(
                            "el-table-column",
                            {
                              key: 56,
                              attrs: {
                                label: "推广地址",
                                prop: "communication_website",
                                align: "center",
                                width: "120px",
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "default",
                                    fn: function (scope) {
                                      return [
                                        scope.row.communication_website.length >
                                        30
                                          ? _c(
                                              "el-tooltip",
                                              {
                                                staticClass: "itemDes",
                                                attrs: {
                                                  content:
                                                    scope.row
                                                      .communication_website,
                                                  "popper-class": "toolp",
                                                  placement: "top-start",
                                                  effect: "light",
                                                },
                                              },
                                              [
                                                _c("span", [
                                                  _vm._v(
                                                    _vm._s(
                                                      scope.row.communication_website.slice(
                                                        0,
                                                        30
                                                      ) + "..."
                                                    )
                                                  ),
                                                ]),
                                              ]
                                            )
                                          : _c("span", [
                                              _vm._v(
                                                _vm._s(
                                                  scope.row
                                                    .communication_website
                                                )
                                              ),
                                            ]),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                false,
                                1042608129
                              ),
                            },
                            [
                              _vm._v(" "),
                              _c("template", { slot: "header" }, [
                                _c(
                                  "span",
                                  {
                                    staticStyle: {
                                      cursor: "pointer",
                                      color: "#1890ff",
                                    },
                                    on: {
                                      click: function ($event) {
                                        return _vm.Activeshow("推广地址")
                                      },
                                    },
                                  },
                                  [_vm._v("推广地址")]
                                ),
                              ]),
                            ],
                            2
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("邮箱")
                        ? _c("el-table-column", {
                            key: 43,
                            attrs: {
                              label: "邮箱",
                              prop: "cemail",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("毕业专业")
                        ? _c("el-table-column", {
                            key: 44,
                            attrs: {
                              label: "毕业专业",
                              prop: "graduation_major",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("护照号码")
                        ? _c("el-table-column", {
                            key: 45,
                            attrs: {
                              label: "护照号码",
                              prop: "passport_number",
                              align: "center",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("最后流转人")
                        ? _c("el-table-column", {
                            key: 47,
                            attrs: { label: "最后流转人", align: "center" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("进入私海日期")
                        ? _c("el-table-column", {
                            key: 48,
                            attrs: { label: "进入私海日期", align: "center" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("最后更新日期")
                        ? _c("el-table-column", {
                            key: 49,
                            attrs: { label: "最后更新日期", align: "center" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.displaySettings.includes("跟进人所属部门")
                        ? _c("el-table-column", {
                            key: 50,
                            attrs: { label: "跟进人所属部门", align: "center" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "el-table-column",
                        {
                          key: 50,
                          attrs: {
                            label: "数据所属部门",
                            prop: "now_structure",
                            align: "center",
                          },
                        },
                        [
                          _c("template", { slot: "header" }, [
                            _c(
                              "span",
                              {
                                staticStyle: {
                                  cursor: "pointer",
                                  color: "#1890ff",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.Activeshow("数据所属部门")
                                  },
                                },
                              },
                              [_vm._v("数据所属部门")]
                            ),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _vm.displaySettings.includes("性别")
                        ? _c("el-table-column", {
                            key: 46,
                            attrs: { label: "性别", align: "center" },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "default",
                                  fn: function (scope) {
                                    return [
                                      _c("span", [
                                        _vm._v(
                                          _vm._s(
                                            scope.row.sex == "0" ? "男" : "女"
                                          )
                                        ),
                                      ]),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              4079631929
                            ),
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    {
                      ref: "tableLeft",
                      attrs: { id: "tableLeft" },
                      on: {
                        scroll: function ($event) {
                          return _vm.exterHandleScroll()
                        },
                      },
                    },
                    [
                      _c("div", {
                        staticStyle: { height: "10px" },
                        attrs: { id: "bodyLeft" },
                      }),
                    ]
                  ),
                ],
              ],
              2
            ),
            _vm._v(" "),
            _c("pagination", {
              attrs: {
                "current-page": _vm.query.page,
                totalPage: _vm.query.total,
                pageSize: _vm.query.pagesize,
              },
              on: {
                handleSizeChange: _vm.handleSizeChange,
                handleCurrentChange: _vm.handleCurrentChange,
              },
            }),
          ],
          1
        )
      : _c(
          "div",
          {
            staticClass: "boxImg",
            staticStyle: { width: "100%", padding: "20px" },
          },
          [_c("system", { attrs: { title: _vm.title, tab: _vm.tab } })],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }